//
//
//
//
//
//
//
//
//
//
//
//
//

import dayjs from 'dayjs';

export default {
  filters: {
    formatNumber(value) {
      return new Intl.NumberFormat('de-DE').format(Number(value).toFixed(2));
    }
  },
  props: {
    offer: Object,
    startDate: String,
    daysToFinish: Number,
    hiredPower: String,
    selectedRates: []
  },
  data: () => ({
    feeEnergy: {
      p1: 0,
      p2: 0,
      p3: 0,
      p4: 0,
      p5: 0,
      p6: 0
    },
    feePower: {
      p1: 0,
      p2: 0,
      p3: 0,
      p4: 0,
      p5: 0,
      p6: 0
    }
  }),
  computed: {
    pricesPerPower() {
      this.extractFeePower();
      let pricesPeriodPower = this.extractPrices(this.offer.precioPotencias);
      let pricesPower = this.offer.precioPotencias;

      if (pricesPeriodPower.length === 0) {
        pricesPeriodPower = this.extractPrices(this.offer.potencia.precios);
        pricesPower = this.offer.potencia.precios;
      }

      pricesPower.p1 += this.feePower.p1;
      pricesPower.p2 += this.feePower.p2;
      pricesPower.p3 += this.feePower.p3;
      pricesPower.p4 += this.feePower.p4;
      pricesPower.p5 += this.feePower.p5;
      pricesPower.p6 += this.feePower.p6;

      const prices = pricesPeriodPower.map(key => {
        let price = pricesPower[key];

        /**
         * Cuando el precio no es el del BOE nos llega el precio en kW/año, hay que dividir por 365 para obtener el precio por día
         */
        if (this.offer.precioBoe === 'N') {
          price /= 365;
        }

        return `${key.toUpperCase()}: ${price.toFixed(6)} €/kW/día`;
      });

      const last = prices.pop();

      return `${prices.join(', ')} y ${last}`;
    },
    pricesPerEnergy() {
      this.extractFeeEnergy();
      let pricesPeriodEnergy = this.extractPrices(this.offer.precioEnergia);
      let pricesEnergy = this.offer.precioEnergia;

      if (pricesPeriodEnergy.length === 0) {
        pricesPeriodEnergy = this.extractPrices(this.offer.energia.precios);
        pricesEnergy = this.offer.energia.precios;
      }

      pricesEnergy.p1 += this.feeEnergy.p1;
      pricesEnergy.p2 += this.feeEnergy.p2;
      pricesEnergy.p3 += this.feeEnergy.p3;
      pricesEnergy.p4 += this.feeEnergy.p4;
      pricesEnergy.p5 += this.feeEnergy.p5;
      pricesEnergy.p6 += this.feeEnergy.p6;

      const prices = pricesPeriodEnergy.map(
        key => `${key.toUpperCase()}: ${pricesEnergy[key]}/kWh`
      );

      const last = prices.pop();

      return `${prices.join(', ')} y ${last}`;
    },
    offerExpirationDate() {
      const startDate = dayjs(this.startDate);

      return {
        start: startDate.format('DD/MM/YYYY'),
        end: startDate.add(this.daysToFinish, 'days').format('DD/MM/YYYY')
      };
    }
  },
  methods: {
    extractPrices(periodos) {
      return Object.keys(periodos).filter(key => !['0', 0, null].includes(periodos[key]));
    },
    extractFeeEnergy() {
      this.selectedRates.forEach(rate => {
        if (rate.alias === this.offer.aliasTarifa) {
          this.feeEnergy.p1 = rate.values.feesEnergy.selected.p1 / 1000;
          this.feeEnergy.p2 = rate.values.feesEnergy.selected.p2 / 1000;
          this.feeEnergy.p3 = rate.values.feesEnergy.selected.p3 / 1000;
          this.feeEnergy.p4 = rate.values.feesEnergy.selected.p4 / 1000;
          this.feeEnergy.p5 = rate.values.feesEnergy.selected.p5 / 1000;
          this.feeEnergy.p6 = rate.values.feesEnergy.selected.p6 / 1000;
        }
      });
    },
    extractFeePower() {
      this.selectedRates.forEach(rate => {
        if (rate.alias === this.offer.aliasTarifa) {
          this.feePower.p1 = rate.values.powerMargin.selected.p1 / 365;
          this.feePower.p2 = rate.values.powerMargin.selected.p2 / 365;
          this.feePower.p3 = rate.values.powerMargin.selected.p3 / 365;
          this.feePower.p4 = rate.values.powerMargin.selected.p4 / 365;
          this.feePower.p5 = rate.values.powerMargin.selected.p5 / 365;
          this.feePower.p6 = rate.values.powerMargin.selected.p6 / 365;
        }
      });
    }
  }
};
